import React, { useState } from "react";
import "./App.css";

const App = () => {
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  const generateResponse = async () => {
    if (!prompt) {
      setResponse("You gotta chill and type something, my dude.");
      return;
    }

    setLoading(true);
    try {
      const result = await fetch("/api/openai", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt }),
      });

      const data = await result.json();

      if (data.error) {
        setResponse(`Whoa, error alert: ${data.error}`);
      } else if (data.message) {
        setResponse(data.message.trim());
      } else {
        setResponse("Nothing to say? That's chill.");
      }
    } catch (error) {
      console.error("Error calling API:", error);
      setResponse("Something went wrong, bro. Try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chillguy-container">

      <main className="chillguy-main">
        <div className="chillguy-box-container">
          
      <header className="chillguy-header">
        <div className="chillguy-twitter-logo-container">
          <a
            href="https://x.com/Chill_GPT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/x-logo.png"
              alt="Twitter X Logo"
              className="chillguy-twitter-logo"
            />
          </a>
        </div>
        <h1 className="chillguy-title">ChillGPT</h1>
        <p className="chillguy-subtitle">Just a Chill GPT</p>
      </header>
          <div className="chillguy-box">
            {/* <h2 className="chillguy-subtitle-lg">What’s on your mind, my friend?</h2> */}
            <textarea
              className="chillguy-input"
              value={prompt}
              onChange={handleInputChange}
              placeholder="Type your chill thoughts here..."
            />
            <button
              className="chillguy-button"
              onClick={generateResponse}
              disabled={loading}
            >
              {loading ? "Chilling..." : "ChillGPT it"}
            </button>
          </div>
          </div>

          <div className="chillguy-response-container">
            <div className="chillguy-text-bubble">{response || "Hey dude, good to see you. What's on your mind? You can tell me anything, I'm just a chill GPT."}</div>
            <img
              src="/chillguy.gif"
              alt="Chill Guy Talking"
              className="chillguy-response-gif"
            />
          </div>
      </main>

      <footer className="chillguy-footer">
        <p>Built for Chill People by Chill People 💚💜</p>
      </footer>
    </div>
  );
};

export default App;
